import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

/**
 * useAuthority is a custom hook that checks if the user has the required authority.
 *
 * @param {Array} userAuthority - The user's authority.
 * @param {Array} authority - The required authority.
 * @param {boolean} emptyCheck - If true, check if the authority is empty.
 * @returns {boolean}
 */
function useAuthority(userAuthority = [], authority = [], emptyCheck = false) {
    const roleMatched = useMemo(() => {
        return authority.some((role) => userAuthority.includes(role));
    }, [authority, userAuthority]);

    if (
        isEmpty(authority) ||
        isEmpty(userAuthority) ||
        typeof authority === 'undefined'
    ) {
        return !emptyCheck;
    }

    return roleMatched;
}

export default useAuthority;
