import Table from './Table';
import THead from './THead';
import TBody from './TBody';
import TFoot from './TFoot';
import Tr from './Tr';
import Th from './Th';
import Td from './Td';
import Sorter from './Sorter';

Table.THead = THead;
Table.TBody = TBody;
Table.TFoot = TFoot;
Table.Th = Th;
Table.Tr = Tr;
Table.Td = Td;
Table.Sorter = Sorter;

export default Table;
