import React, { useRef, forwardRef } from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Input from '../Input';
import useRootClose from '../hooks/useRootClose';
import { usePopper } from 'react-popper';
import useMergedRef from '../hooks/useMergeRef';
import { HiOutlineCalendar } from 'react-icons/hi';
import CloseButton from '../CloseButton';

dayjs.extend(localizedFormat);

/**
 * BasePicker is a custom component that wraps the Input component and the Picker component.
 * It uses the `forwardRef` function to pass the ref to the container.
 *
 * @param {object} props
 * @param {string} [props.className] - The class name of the
 * @param {React.ReactNode} props.clearButton - The clear button.
 * @param {React.ReactNode} props.children - The children of the picker.
 * @param {string} props.form - The form of the picker.
 * @param {string} props.field - The field of the picker.
 * @param {string} props.size - The size of the picker.
 * @param {boolean} props.inputtable - The flag to check if the picker is inputtable.
 * @param {boolean} props.inputtableBlurClose - The flag to check if the picker is inputtable blur close.
 * @param {string} props.inputLabel - The input label of the picker.
 * @param {React.ReactNode} props.inputPrefix - The input prefix of the picker.
 * @param {React.ReactNode} props.inputSuffix - The input suffix of the picker.
 * @param {boolean} props.clearable - The flag to check if the picker is clearable.
 * @param {string} props.placeholder - The placeholder of the picker.
 * @param {string} props.name - The name of the picker.
 * @param {boolean} props.dropdownOpened - The flag to check if the dropdown is opened.
 * @param {function} props.setDropdownOpened - The function to set the dropdown opened.
 * @param {function} props.onDropdownOpen - The function to call when the dropdown is opened.
 * @param {function} props.onDropdownClose - The function to call when the dropdown is closed.
 * @param {function} props.onBlur - The function to call when the picker is blurred.
 * @param {function} props.onFocus - The function to call when the picker is focused.
 * @param {function} props.onChange - The function to call when the picker is changed.
 * @param {function} props.onKeyDown - The function to call when the key is down.
 * @param {function} props.onClear - The function to call when the picker is cleared.
 * @param {boolean} props.disabled - The flag to check if the picker is disabled.
 * @param {string} props.type - The type of the picker.
 * @param {string} props.placement - The placement of the picker.
 * @returns {React.ReactElement}
 */
const BasePicker = forwardRef((props, ref) => {
    const {
        className,
        clearButton,
        children,
        form,
        field,
        size,
        inputtable,
        inputtableBlurClose = true,
        inputLabel,
        inputPrefix,
        inputSuffix = <HiOutlineCalendar className="text-lg" />,
        clearable = true,
        placeholder,
        name,
        dropdownOpened,
        setDropdownOpened,
        onDropdownOpen,
        onDropdownClose,
        onBlur,
        onFocus,
        onChange,
        onKeyDown,
        onClear,
        disabled,
        type,
        placement = 'bottom-start',
    } = props;

    const handleInputClick = () => {
        !inputtable ? toggleDropdown() : openDropdown();
    };

    const closeDropdown = () => {
        setDropdownOpened(false);
        onDropdownClose?.();
    };

    const suffixIconSlot = clearable ? (
        <div onClick={onClear}>
            {clearButton || <CloseButton className="text-base" />}
        </div>
    ) : (
        <>{inputSuffix}</>
    );

    const toggleDropdown = () => {
        setDropdownOpened(!dropdownOpened);
        !dropdownOpened ? onDropdownOpen?.() : onDropdownClose?.();
    };

    const openDropdown = () => {
        setDropdownOpened(true);
        onDropdownOpen?.();
    };

    const handleKeyDown = (event) => {
        typeof onKeyDown === 'function' && onKeyDown(event);
        if ((event.key === 'Space' || event.key === 'Enter') && !inputtable) {
            event.preventDefault();
            openDropdown();
        }
    };

    const handleInputBlur = (event) => {
        typeof onBlur === 'function' && onBlur(event);
        if (inputtable && inputtableBlurClose) {
            closeDropdown();
        }
    };

    const handleInputFocus = (event) => {
        typeof onFocus === 'function' && onFocus(event);
        if (inputtable) {
            openDropdown();
        }
    };

    const referenceRef = useRef(null);
    const popperRef = useRef(null);

    const { styles, attributes } = usePopper(
        referenceRef.current,
        popperRef.current,
        {
            placement: placement,
            modifiers: [
                {
                    name: 'offset',
                    enabled: true,
                    options: {
                        offset: [0, 10],
                    },
                },
            ],
        }
    );

    useRootClose(() => closeDropdown(), {
        triggerTarget: referenceRef,
        overlayTarget: popperRef,
        disabled: !dropdownOpened,
        listenEscape: false,
    });

    return (
        <>
            <Input
                form={form}
                field={field}
                className={className}
                placeholder={placeholder}
                ref={useMergedRef(ref, referenceRef)}
                size={size}
                name={name}
                value={inputLabel}
                readOnly={!inputtable}
                suffix={suffixIconSlot}
                prefix={inputPrefix}
                onClick={handleInputClick}
                onKeyDown={handleKeyDown}
                onBlur={handleInputBlur}
                onFocus={handleInputFocus}
                onChange={onChange}
                autoComplete="off"
                type={type}
                disabled={disabled}
            />
            <div
                className="picker"
                ref={popperRef}
                style={styles.popper}
                {...attributes.popper}
            >
                {dropdownOpened && (
                    <div className="picker-panel">{children}</div>
                )}
            </div>
        </>
    );
});

export default BasePicker;
