import React, { useCallback, useContext } from 'react';
import Menu from './Menu';
import MenuContext from './context/menuContext';
import DropdownItem from './DropdownItem';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * DropdownMenu component is a custom component to render a dropdown menu.
 * It uses the `MenuContext` to manage the menu state.
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - The children of the dropdown menu.
 * @param {string} [props.className] - The class name of the
 * @param {string} [props.placement] - The placement of the dropdown menu.
 * @param {function} [props.onToggle] - The function to call when the dropdown menu toggles.
 * @param {string} [props.eventKey] - The event key of the dropdown menu.
 * @param {React.ReactNode} [props.title] - The title of the dropdown menu.
 * @returns {React.ReactElement}
 */
const DropdownMenu = React.forwardRef((props, ref) => {
    const { onToggle, eventKey, title, className, placement, ...rest } = props;

    const parentMenu = useContext(MenuContext);

    const handleToggleSubmenu = useCallback(
        (_, e) => {
            onToggle?.(eventKey, e);
        },
        [eventKey, onToggle]
    );

    const dropdownMenuDefaultClass = `dropdown-menu`;
    const dropdownMenuPositionClass = placement;

    const dropdownMenuClass = classNames(
        dropdownMenuDefaultClass,
        dropdownMenuPositionClass,
        className
    );

    const dropdownSubmenuClass = classNames(
        dropdownMenuDefaultClass,
        'dropdown-submenu'
    );

    const dropdownSubmenu = (
        <Menu
            className={dropdownSubmenuClass}
            ref={ref}
            onToggle={handleToggleSubmenu}
            placement={placement}
            {...rest}
        />
    );

    if (parentMenu) {
        const { icon, trigger } = props;
        const itemClassName = classNames(className);

        return (
            <DropdownItem
                icon={icon}
                trigger={trigger}
                className={itemClassName}
                submenu={dropdownSubmenu}
                eventKey={eventKey}
            >
                {title}
            </DropdownItem>
        );
    }

    return (
        <Menu
            className={dropdownMenuClass}
            placement={placement}
            ref={ref}
            {...rest}
        />
    );
});

DropdownMenu.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    eventKey: PropTypes.string,
};

export default DropdownMenu;
