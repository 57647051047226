import React from 'react';
import classNames from 'classnames';
import Button from '../../Buttons';
import { HiChevronRight, HiChevronLeft } from 'react-icons/hi';

/**
 * Header component is a custom header component.
 * It uses the `classNames` utility to conditionally apply classes.
 *
 * @param {object} props
 * @param {boolean} props.hasNext - The flag to check if there is a next item.
 * @param {boolean} props.hasPrevious - The flag to check if there is a previous item.
 * @param {function} props.onNext - The function to call when the next button is clicked.
 * @param {function} props.onPrevious - The function to call when the previous button is clicked.
 * @param {function} props.onNextLevel - The function to call when the next level button is clicked.
 * @param {string} [props.className] - The class name of the header.
 * @param {string} props.label - The label of the header.
 * @param {boolean} props.nextLevelDisabled - The flag to disable the next level button.
 * @param {object} props.style - The style of the header.
 * @param {string} [props.nextLabel] - The label of the next button.
 * @param {string} [props.previousLabel] - The label of the previous button.
 * @param {boolean} [props.preventLevelFocus] - The flag to prevent the level focus.
 * @param {boolean} [props.renderCenter] - The flag to render the center.
 * @param {boolean} [props.preventFocus] - The flag to prevent the focus.
 * @param {React.ReactNode} [props.children] - The children of the header.
 * @returns {React.ReactElement}
 */
const Header = (props) => {
    const {
        hasNext,
        hasPrevious,
        onNext,
        onPrevious,
        onNextLevel,
        className,
        label,
        nextLevelDisabled,
        style,
        nextLabel,
        previousLabel,
        preventLevelFocus = false,
        renderCenter = false,
        preventFocus,
        children,
        ...rest
    } = props;

    const headerLabel = (
        <button
            className="picker-header-label"
            disabled={nextLevelDisabled}
            onClick={onNextLevel}
            tabIndex={preventLevelFocus ? -1 : 0}
            onMouseDown={(event) => preventFocus && event.preventDefault()}
            type="button"
        >
            {label}
        </button>
    );

    const renderChildren = children ? children : headerLabel;

    return (
        <div
            className="picker-header flex items-center justify-between mb-2"
            {...rest}
        >
            {!renderCenter && renderChildren}
            <div
                className={classNames(
                    renderCenter && 'justify-between w-full',
                    'flex items-center rtl:flex-row-reverse'
                )}
            >
                <Button
                    type="button"
                    variant="plain"
                    className={classNames(
                        !hasPrevious &&
                            renderCenter &&
                            'opacity-0 cursor-default'
                    )}
                    size="sm"
                    icon={<HiChevronLeft />}
                    disabled={!hasPrevious}
                    onClick={onPrevious}
                    aria-label={previousLabel}
                    onMouseDown={(event) =>
                        preventFocus && event.preventDefault()
                    }
                />
                {renderCenter && renderChildren}
                <Button
                    type="button"
                    variant="plain"
                    className={classNames(
                        !hasNext && renderCenter && 'opacity-0 cursor-default'
                    )}
                    size="sm"
                    icon={<HiChevronRight />}
                    disabled={!hasNext}
                    onClick={onNext}
                    aria-label={nextLabel}
                    onMouseDown={(event) =>
                        preventFocus && event.preventDefault()
                    }
                />
            </div>
        </div>
    );
};

export default Header;
