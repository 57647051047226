/**
 * Capitalize the first letter of a string
 * @param {string} value - The string to capitalize
 * @returns {string} The capitalized string
 */
export default function capitalize(value) {
    return typeof value !== 'string'
        ? ''
        : value.charAt(0).toUpperCase() + value.slice(1);
}
