/**
 * Get the acronym of a string
 * @param {string} name - The string to get the acronym from
 * @returns {string} - The acronym
 */
export default function acronym(name = '') {
    const shortName = name.match(/\b(\w)/g);

    if (shortName) {
        return shortName.join('');
    }

    return name;
}
