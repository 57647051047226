import { useEffect, useRef } from 'react';

/**
 * useDidUpdate is a custom hook to run a callback when a component updates.
 *
 * @param {Function} callback - The callback function.
 * @param {Array} dependencies - The dependencies array.
 */
export default function useDidUpdate(callback, dependencies) {
    const mounted = useRef(false);

    useEffect(
        () => () => {
            mounted.current = false;
        },
        []
    );

    useEffect(() => {
        if (mounted.current) {
            return callback();
        }

        mounted.current = true;
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
}
