import React, { useState, useRef, forwardRef } from 'react';
import dayjs from 'dayjs';
import useControllableState from '../hooks/useControllableState';
import useMergedRef from '../hooks/useMergeRef';
import capitalize from '../utils/capitalize';
import RangeCalendar from './RangeCalendar';
import BasePicker from './BasePicker';
import { useConfig } from '../ConfigProvider';

const validationRule = (val) =>
    Array.isArray(val) &&
    val.length === 2 &&
    val.every((v) => v instanceof Date);

const isFirstDateSet = (val) =>
    Array.isArray(val) && val.length === 2 && val[0] instanceof Date;

/**
 * DatePickerRange component is a date picker component that allows users to select a range of dates.
 * @param {Object} props - The component props
 * @param {string} [props.className] - The component class name
 * @param {boolean} [props.clearable] - The flag to enable the clear button
 * @param {boolean} [props.clearButton] - The flag to show the clear button
 * @param {boolean} [props.closePickerOnChange] - The flag to close the picker on change
 * @param {number} [props.dateViewCount] - The number of date views
 * @param {function} [props.dayClassName] - The function to add a class to the day
 * @param {object} [props.dayStyle] - The style to add to the day
 * @param {Date} [props.defaultMonth] - The default month
 * @param {boolean} [props.defaultOpen] - The flag to open the picker by default
 * @param {Date[]} [props.defaultValue] - The default value
 * @param {string} [props.defaultView] - The default view
 * @param {boolean} [props.disabled] - The flag to disable the picker
 * @param {function} [props.disableDate] - The function to disable the date
 * @param {boolean} [props.enableHeaderLabel] - The flag to enable the header label
 * @param {boolean} [props.disableOutOfMonth] - The flag to disable out of month dates
 * @param {string} [props.firstDayOfWeek] - The first day of the week
 * @param {boolean} [props.hideOutOfMonthDates] - The flag to hide out of month dates
 * @param {boolean} [props.hideWeekdays] - The flag to hide weekdays
 * @param {string} [props.inputFormat] - The input format
 * @param {string} [props.inputPrefix] - The input prefix
 * @param {string} [props.inputSuffix] - The input suffix
 * @param {object} [props.labelFormat] - The label format
 * @param {string} [props.locale] - The locale
 * @param {Date} [props.maxDate] - The maximum date
 * @param {Date} [props.minDate] - The minimum date
 * @param {function} [props.onChange] - The function to call when the value changes
 * @param {function} [props.onDropdownClose] - The function to call when the dropdown closes
 * @param {function} [props.onDropdownOpen] - The function to call when the dropdown opens
 * @param {boolean} [props.openPickerOnClear] - The flag to open the picker on clear
 * @param {function} [props.renderDay] - The function to render the day
 * @param {boolean} [props.singleDate] - The flag to enable single date selection
 * @param {string} [props.size] - The size of the picker
 * @param {object} [props.style] - The style of the picker
 * @param {Date[]} [props.value] - The value
 * @param {string[]} [props.weekendDays] - The weekend days
 * @param {object} [props.yearLabelFormat] - The year label format
 * @returns {React.ReactElement}
 */
const DatePickerRange = forwardRef((props, ref) => {
    const {
        className,
        clearable,
        clearButton,
        closePickerOnChange,
        dateViewCount,
        dayClassName,
        dayStyle,
        defaultMonth,
        defaultOpen,
        defaultValue,
        defaultView,
        disabled,
        disableDate,
        enableHeaderLabel,
        disableOutOfMonth,
        firstDayOfWeek,
        hideOutOfMonthDates,
        hideWeekdays,
        inputFormat,
        inputPrefix,
        inputSuffix,
        labelFormat,
        seperator,
        locale,
        maxDate,
        minDate,
        onChange,
        onDropdownClose,
        onDropdownOpen,
        openPickerOnClear,
        renderDay,
        singleDate,
        size,
        style,
        value,
        weekendDays,
        yearLabelFormat,
        ...rest
    } = props;

    const { locale: themeLocale } = useConfig();

    const finalLocale = locale || themeLocale;

    const dateFormat = inputFormat || 'YYYY-MM-DD';

    const [dropdownOpened, setDropdownOpened] = useState(defaultOpen);

    const inputRef = useRef();

    const [_value, setValue] = useControllableState({
        prop: value,
        defaultProp: defaultValue !== undefined ? defaultValue : [null, null],
        onChange,
    });

    const handleValueChange = (range) => {
        setValue(range);
        if (closePickerOnChange && validationRule(range)) {
            setDropdownOpened(false);
            onDropdownClose?.();
            window.setTimeout(() => inputRef.current?.focus(), 0);
        }
    };

    const valueValid = validationRule(_value);
    const firstValueValid = isFirstDateSet(_value);

    const firstDateLabel = _value[0]
        ? capitalize(dayjs(_value[0]).locale(finalLocale).format(dateFormat))
        : '';

    const secondDateLabel = _value[1]
        ? capitalize(dayjs(_value[1]).locale(finalLocale).format(dateFormat))
        : '';

    const handleClear = () => {
        setValue([null, null]);
        setDropdownOpened(true);
        openPickerOnClear && onDropdownOpen?.();
        inputRef.current?.focus();
    };

    const handleDropdownToggle = (isOpened) => {
        if (!isOpened && firstValueValid && _value[1] === null) {
            handleClear();
        }
        setDropdownOpened(isOpened);
    };

    return (
        <BasePicker
            dropdownOpened={dropdownOpened}
            setDropdownOpened={handleDropdownToggle}
            ref={useMergedRef(ref, inputRef)}
            size={size}
            style={style}
            className={className}
            inputLabel={
                firstValueValid
                    ? `${firstDateLabel} ${seperator} ${secondDateLabel}`
                    : ''
            }
            clearable={clearable && firstValueValid}
            clearButton={clearButton}
            onClear={handleClear}
            dateViewCount={dateViewCount}
            onDropdownClose={onDropdownClose}
            onDropdownOpen={onDropdownOpen}
            disabled={disabled}
            inputPrefix={inputPrefix}
            inputSuffix={inputSuffix}
            {...rest}
        >
            <RangeCalendar
                locale={finalLocale}
                defaultMonth={valueValid ? _value[0] : defaultMonth}
                value={_value}
                onChange={handleValueChange}
                labelFormat={labelFormat}
                dayClassName={dayClassName}
                dayStyle={dayStyle}
                disableOutOfMonth={disableOutOfMonth}
                minDate={minDate}
                maxDate={maxDate}
                disableDate={disableDate}
                firstDayOfWeek={firstDayOfWeek}
                enableHeaderLabel={enableHeaderLabel}
                singleDate={singleDate}
                dateViewCount={dateViewCount}
                defaultView={defaultView}
                hideOutOfMonthDates={hideOutOfMonthDates}
                hideWeekdays={hideWeekdays}
                renderDay={renderDay}
                weekendDays={weekendDays}
                yearLabelFormat={yearLabelFormat}
            />
        </BasePicker>
    );
});

DatePickerRange.defaultProps = {
    closePickerOnChange: true,
    labelFormat: {
        month: 'MMM',
        year: 'YYYY',
    },
    defaultOpen: false,
    seperator: '~',
    clearable: true,
    firstDayOfWeek: 'monday',
    singleDate: false,
    dateViewCount: 1,
    openPickerOnClear: false,
};

export default DatePickerRange;
