import React from 'react';
import classNames from 'classnames';
import Header from './Header';
import { useConfig } from '../../ConfigProvider';
import { isMonthInRange, getMonthsNames, formatYear } from '../utils';

/**
 * MonthTable component is a custom component to render the month table in the date picker.
 * It uses the `classNames` utility to conditionally apply classes.
 * It uses the `Header` component to render the header in the date picker.
 * It uses the `useConfig` hook to get the theme color.
 * It uses the `getMonthsNames` utility to get the months names.
 * It uses the `formatYear` utility to format the year.
 * It uses the `isMonthInRange` utility to check if the month is in range.
 *
 * @param {object} props
 * @param {string} [props.className] - The class name of the
 * month table.
 * @param {object} props.value - The value of the month table.
 * @param {function} props.onChange - The function to call when
 * the month changes.
 * @param {string} props.locale - The locale to use.
 * @param {number} props.year - The year to render.
 * @param {function} props.onYearChange - The function to call
 * when the year changes.
 * @param {function} props.onNextLevel - The function to call
 * when the next level is clicked.
 * @param {Date} props.minDate - The minimum date.
 * @param {Date} props.maxDate - The maximum date.
 * @param {boolean} [props.preventFocus] - The flag to prevent
 * the focus.
 * @param {string} [props.monthLabelFormat] - The format of the
 * month label.
 * @param {string} [props.yearLabelFormat] - The format of the
 * year label.
 * @returns {React.ReactElement}
 */
const MonthTable = (props) => {
    const {
        className,
        value,
        onChange,
        locale,
        year,
        onYearChange,
        onNextLevel,
        minDate,
        maxDate,
        preventFocus,
        monthLabelFormat = 'MMM',
        yearLabelFormat = 'YYYY',
        ...rest
    } = props;

    const { themeColor, primaryColorLevel } = useConfig();

    const range = getMonthsNames(locale, monthLabelFormat);
    const minYear = minDate instanceof Date ? minDate.getFullYear() : undefined;
    const maxYear = maxDate instanceof Date ? maxDate.getFullYear() : undefined;

    const months = range.map((month, index) => {
        const disabled = !isMonthInRange({
            date: new Date(year, index),
            minDate,
            maxDate,
        });

        const active = index === value.month && year === value.year;

        return (
            <button
                key={month}
                onClick={() => onChange(index)}
                className={classNames(
                    'year-picker-cell',
                    active &&
                        !disabled &&
                        `bg-${themeColor}-${primaryColorLevel} text-white month-picker-cell-active`,
                    !active && !disabled && 'hover:bg-gray-100',
                    disabled && 'month-picker-cell-disabled'
                )}
                disabled={disabled}
                onMouseDown={(event) => preventFocus && event.preventDefault()}
            >
                {month}
            </button>
        );
    });

    return (
        <div className={classNames('month-picker', className)} {...rest}>
            <Header
                label={formatYear(year, yearLabelFormat)}
                hasNext={typeof maxYear === 'number' ? year < maxYear : true}
                hasPrevious={
                    typeof minYear === 'number' ? year > minYear : true
                }
                onNext={() => onYearChange(year + 1)}
                onPrevious={() => onYearChange(year - 1)}
                onNextLevel={onNextLevel}
                className={className}
                nextLabel={'Next year'}
                previousLabel={'Previous year'}
                preventFocus={preventFocus}
            />
            <div className="month-table">{months}</div>
        </div>
    );
};

export default MonthTable;
