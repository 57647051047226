import React, { useState, useCallback, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { CheckboxGroupContextProvider } from './context';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import shallowEqual from '../utils/shallowEqual';

/**
 * Group component is a custom group component for checkboxes.
 * It uses the `forwardRef` function to pass the ref to the group.
 * It uses the `useState` hook to manage the group state.
 * It uses the `useCallback` hook to memoize the change event handler.
 * It uses the `useEffect` hook to update the group state.
 * It uses the `classNames` utility to conditionally apply classes.
 * It uses the `CheckboxGroupContextProvider` component to provide the context.
 *
 * @param {string} children - The content of the group.
 * @param {string} color - The color of the group.
 * @param {string} className - The class name of the group.
 * @param {boolean} vertical - Whether the group is vertical.
 * @param {string} name - The name of the group.
 * @param {array} value - The value of the group.
 * @param {function} onChange - The function to handle the change event.
 * @returns {JSX.Element} - The Group component.
 */
const Group = React.forwardRef((props, ref) => {
    const {
        value: valueProp,
        className,
        onChange,
        color,
        vertical,
        name,
        children,
        ...rest
    } = props;

    const [value, setValue] = useState(valueProp);

    const onCheckboxGroupChange = useCallback(
        (itemValue, itemChecked, event) => {
            const nextValue = cloneDeep(value) || [];
            if (itemChecked) {
                nextValue.push(itemValue);
            } else {
                remove(nextValue, (i) => shallowEqual(i, itemValue));
            }

            setValue(nextValue);
            onChange?.(nextValue, event);
        },
        [onChange, setValue, value]
    );

    useEffect(() => {
        setValue(valueProp);
    }, [valueProp]);

    const checkboxGroupDefaultClass = `inline-flex ${
        vertical ? 'flex-col gap-y-2' : ''
    }`;

    const checkBoxGroupClass = classNames(checkboxGroupDefaultClass, className);

    const contextValue = useMemo(
        () => ({
            vertical,
            name,
            value,
            color,
            onChange: onCheckboxGroupChange,
        }),
        [vertical, onCheckboxGroupChange, name, color, value]
    );

    return (
        <CheckboxGroupContextProvider value={contextValue}>
            <div ref={ref} className={checkBoxGroupClass} {...rest}>
                {children}
            </div>
        </CheckboxGroupContextProvider>
    );
});

Group.defaultProps = {
    vertical: false,
};

Group.propTypes = {
    vertical: PropTypes.bool,
    color: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.string),
    ]),
    onChange: PropTypes.func,
};

export default Group;
