import { useRef, useEffect, useMemo } from 'react';

/**
 * useCallbackRef is a custom hook to create a callback ref.
 *
 * @param {Function} cb - The callback function.
 * @returns {Function}
 */
function useCallbackRef(cb) {
    const cbRef = useRef(cb);

    useEffect(() => {
        cbRef.current = cb;
    });

    return useMemo(
        () =>
            (...args) =>
                cbRef.current?.(...args),
        []
    );
}

export default useCallbackRef;
