import React from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { isMonthInRange } from '../utils/isMonthInRange';
import Header from './Header';
import Month from './components/Month';
import capitalize from '../../utils/capitalize';
import { useConfig } from '../../ConfigProvider';

function formatMonthLabel({ month, locale, format }) {
    return capitalize(dayjs(month).locale(locale).format(format));
}

/**
 * DateTable component is a custom component to render the date table in the date picker.
 * It uses the `classNames` utility to conditionally apply classes.
 * It uses the `dayjs` library to work with dates.
 * It uses the `Header` component to render the header in the date picker.
 * It uses the `Month` component to render the month in the date picker.
 * It uses the `isMonthInRange` utility to check if the month is in range.
 * It uses the `useConfig` hook to get the theme color.
 * It uses the `formatMonthLabel` utility to format the month label.
 *
 * @param {object} props
 * @param {number} props.dateViewCount - The date view count.
 * @param {number} props.paginateBy - The paginate by value.
 * @param {Date} props.month - The month to render.
 * @param {string} props.locale - The locale to use.
 * @param {Date} props.minDate - The minimum date.
 * @param {Date} props.maxDate - The maximum date.
 * @param {boolean} props.enableHeaderLabel - The flag to enable the header label.
 * @param {object} props.daysRefs - The refs of the days.
 * @param {function} props.onMonthChange - The function to call when the month changes.
 * @param {function} props.onNextLevel - The function to call when the next level is clicked.
 * @param {function} props.onDayKeyDown - The function to call when the key is down on the day.
 * @param {string} props.className - The class name of the date table.
 * @param {object} props.labelFormat - The format of the label.
 * @param {object} props.weekdayLabelFormat - The format of the weekday label.
 * @param {boolean} props.preventFocus - The flag to prevent the focus.
 * @param {function} props.renderDay - The function to render the day.
 * @returns {React.ReactElement}
 */
const DateTable = (props) => {
    const {
        dateViewCount,
        paginateBy,
        month,
        locale,
        minDate,
        maxDate,
        enableHeaderLabel,
        daysRefs,
        onMonthChange,
        onNextLevel,
        onDayKeyDown,
        className,
        labelFormat,
        weekdayLabelFormat,
        preventFocus,
        renderDay,
        ...rest
    } = props;

    const nextMonth = dayjs(month).add(dateViewCount, 'months').toDate();
    const previousMonth = dayjs(month).subtract(1, 'months').toDate();

    const { themeColor, primaryColorLevel } = useConfig();

    const pickerHeaderLabelClass = `picker-header-label hover:text-${themeColor}-${primaryColorLevel}`;

    const months = Array(dateViewCount)
        .fill(0)
        .map((_, index) => {
            const monthDate = dayjs(month).add(index, 'months').toDate();
            return (
                <div className="day-picker" key={index}>
                    <Header
                        hasNext={
                            index + 1 === dateViewCount &&
                            isMonthInRange({
                                date: nextMonth,
                                minDate,
                                maxDate,
                            })
                        }
                        hasPrevious={
                            index === 0 &&
                            isMonthInRange({
                                date: previousMonth,
                                minDate,
                                maxDate,
                            })
                        }
                        onNext={() =>
                            onMonthChange(
                                dayjs(month).add(paginateBy, 'months').toDate()
                            )
                        }
                        onPrevious={() =>
                            onMonthChange(
                                dayjs(month)
                                    .subtract(paginateBy, 'months')
                                    .toDate()
                            )
                        }
                        className={className}
                        renderCenter={dateViewCount > 1}
                    >
                        <div>
                            <button
                                className={classNames(pickerHeaderLabelClass)}
                                disabled={!enableHeaderLabel}
                                onClick={() => onNextLevel('month')}
                                tabIndex={index > 0 ? -1 : 0}
                                onMouseDown={(event) =>
                                    preventFocus && event.preventDefault()
                                }
                            >
                                {formatMonthLabel({
                                    month: monthDate,
                                    locale,
                                    format: labelFormat?.month || 'MMM',
                                })}
                            </button>
                            <button
                                className={classNames(pickerHeaderLabelClass)}
                                disabled={!enableHeaderLabel}
                                onClick={() => onNextLevel('year')}
                                tabIndex={index > 0 ? -1 : 0}
                                onMouseDown={(event) =>
                                    preventFocus && event.preventDefault()
                                }
                            >
                                {formatMonthLabel({
                                    month: monthDate,
                                    locale,
                                    format: labelFormat?.year || 'YYYY',
                                })}
                            </button>
                        </div>
                    </Header>
                    <Month
                        month={monthDate}
                        daysRefs={daysRefs.current[index]}
                        onDayKeyDown={(...args) => onDayKeyDown(index, ...args)}
                        minDate={minDate}
                        maxDate={maxDate}
                        className={className}
                        locale={locale}
                        focusable={index === 0}
                        preventFocus={preventFocus}
                        renderDay={renderDay}
                        weekdayLabelFormat={weekdayLabelFormat}
                        {...rest}
                    />
                </div>
            );
        });

    return <>{months}</>;
};

export default DateTable;
