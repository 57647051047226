import React from 'react';

/**
 * Total component is a component to render total element.
 *
 * @param {Object} props - The props of the component.
 * @returns {React.Element}
 */
const Total = (props) => {
    const { total } = props;
    return (
        <div className="pagination-total">
            Total <span>{total}</span> Items
        </div>
    );
};

export default Total;
