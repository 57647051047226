import React from 'react';
import classNames from 'classnames';
import { HiChevronLeft } from 'react-icons/hi';

/**
 * Prev component is a component to render prev element.
 *
 * @param {Object} props - The props of the component.
 * @returns {React.Element}
 */
const Prev = (props) => {
    const { currentPage, pagerClass, onPrev } = props;

    const disabled = currentPage <= 1;

    const onPrevClick = (e) => {
        if (disabled) {
            return;
        }
        onPrev(e);
    };

    const pagerPrevClass = classNames(
        pagerClass.default,
        'pagination-pager-prev',
        disabled ? pagerClass.disabled : pagerClass.inactive
    );

    return (
        <span className={pagerPrevClass} onClick={onPrevClick}>
            <HiChevronLeft />
        </span>
    );
};

export default Prev;
