import { createSlice } from '@reduxjs/toolkit';

/**
 * Slice for the contacts.
 * This slice handles the contacts.
 * It stores the contacts.
 */
const initialState = {
    contacts: [],
};

export const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        setContactList: (state, action) => {
            state.contacts = action.payload;
        },
        editContactsList: (state, action) => {
            const updatedContact = action.payload;
            state.contacts = state.contacts.map((item) =>
                item.value === updatedContact.value ? updatedContact : item
            );
        },
        deleteContactList: (state, action) => {
            state.contacts = state.contacts.filter(
                (contact) => contact !== action.payload
            );
        },
        addContact: (state, action) => {
            state.contacts.push(action.payload);
        },
    },
});

export const {
    addContact,
    setContactList,
    editContactsList,
    deleteContactList,
} = contactsSlice.actions;

export default contactsSlice.reducer;
