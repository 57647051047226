import dayjs from 'dayjs';

/**
 * Format year
 * @param {number} year - The year to format.
 * @param {string} format - The format to use.
 * @returns {string}
 */
export function formatYear(year, format) {
    return dayjs(new Date(year, 1, 1)).format(format);
}
