import React from 'react';
import { HiX } from 'react-icons/hi';
import classNames from 'classnames';

/**
 * CloseButton component is a custom close button component.
 * It uses the `forwardRef` function to pass the ref to the close button.
 * It uses the `classNames` utility to conditionally apply classes.
 *
 * @param {boolean} absolute - Whether the close button is absolute.
 * @param {string} className - The class name of the close button.
 * @param {boolean} defaultStyle - Whether the close button has default style.
 * @param {string} svgClass - The class
 * @returns {JSX.Element} - The CloseButton component.
 */
const CloseButton = React.forwardRef((props, ref) => {
    const { absolute, className, defaultStyle, svgClass, ...rest } = props;
    const closeButtonAbsoluteClass = 'absolute z-10';

    const closeButtonClass = classNames(
        'close-btn',
        defaultStyle && 'close-btn-default',
        absolute && closeButtonAbsoluteClass,
        className
    );

    return (
        <span className={closeButtonClass} role="button" {...rest} ref={ref}>
            <HiX />
        </span>
    );
});

CloseButton.defaultProps = {
    defaultStyle: true,
};

export default CloseButton;
