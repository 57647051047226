import React from 'react';
import { useSelector } from 'react-redux';
import { ConfigProvider } from 'components/ui';
import useDarkMode from 'utils/hooks/useDarkMode';
import { themeConfig } from 'configs/theme.config';

/**
 * The `Theme` component provides the theme configuration to the application.
 * It uses the `useSelector` hook to get the state from the redux store
 * It uses the `ConfigProvider` component to provide the theme configuration.
 *
 * @param {object} props - The props of the component.
 * @returns {React.Component} The Theme component
 */
const Theme = (props) => {
    const theme = useSelector((state) => state.theme);
    const locale = useSelector((state) => state.locale.currentLang);
    const [isDark] = useDarkMode();

    const currentTheme = {
        mode: isDark ? 'dark' : 'light',
        ...themeConfig,
        ...theme,
        ...{ locale },
    };

    return (
        <ConfigProvider value={currentTheme}>{props.children}</ConfigProvider>
    );
};

export default Theme;
