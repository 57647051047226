import { combineReducers } from '@reduxjs/toolkit';
import session from './sessionSlice';
import user from './userSlice';

/**
 * Reducer for the authentication and authorization of the user.
 */
const reducer = combineReducers({
    session,
    user,
});

export default reducer;
