import { createSlice } from '@reduxjs/toolkit';

/**
 * Slice for the servers.
 * This slice handles the servers.
 * It stores the servers.
 * The servers are used to store the servers.
 * The servers are used to display the list of servers in the dropdown.
 */
const initialState = {
    servers: [],
};

export const serversSlice = createSlice({
    name: 'servers',
    initialState,
    reducers: {
        setServList: (state, action) => {
            state.servers = action.payload;
        },
        editServersList: (state, action) => {
            const updatedServer = action.payload;
            state.servers = state.servers.map((item) =>
                item.value === updatedServer.value ? updatedServer : item
            );
        },
        deleteServerList: (state, action) => {
            state.servers = state.servers.filter(
                (server) => server !== action.payload
            );
        },
        addServer: (state, action) => {
            state.servers.push(action.payload);
        },
    },
});

export const { addServer, setServList, editServersList, deleteServerList } =
    serversSlice.actions;

export default serversSlice.reducer;
