import { isSameMonth } from './isSameMonth';

/**
 * Check if two dates are the same day.
 * @param {Date} date - The first date to check
 * @param {Date} comparison - The second date to check
 * @returns {boolean}
 */
export function isSameDate(date, comparison) {
    return (
        isSameMonth(date, comparison) && date.getDate() === comparison.getDate()
    );
}
