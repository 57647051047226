import React, {
    forwardRef,
    useMemo,
    useRef,
    useEffect,
    useState,
    useImperativeHandle,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
    Table,
    Pagination,
    Select,
    Checkbox,
    Alert,
    Input,
} from 'components/ui';
import TableRowSkeleton from './loaders/TableRowSkeleton';
import Loading from './Loading';
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
} from '@tanstack/react-table';
import { MdSearch } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

/**
 * DataTable component is a custom component to display data in a table format.
 * It uses the `@tanstack/react-table` library to render the table.
 * The component supports pagination, sorting, and searching.
 *
 * @param {object} props - The props of the component.
 * @param {array} props.columns - The columns of the table.
 * @param {array} props.data - The data to be displayed in the table.
 * @param {boolean} props.loading - The loading state of the table.
 * @param {function} props.onCheckBoxChange - The function to handle checkbox change.
 * @param {function} props.onIndeterminateCheckBoxChange - The function to handle indeterminate checkbox change.
 * @param {function} props.onPaginationChange - The function to handle pagination change.
 * @param {function} props.onSelectChange - The function to handle select change.
 * @param {function} props.onSort - The function to handle sorting.
 * @param {array} props.pageSizes - The page sizes for pagination.
 * @param {boolean} props.selectable - The selectable state of the table.
 * @param {array} props.skeletonAvatarColumns - The columns to show skeleton avatar.
 * @param {object} props.skeletonAvatarProps - The props for the skeleton avatar.
 * @param {object} props.pagingData - The pagination data.
 * @returns {JSX.Element} - The DataTable component.
 */
const { Tr, Th, Td, THead, TBody, Sorter } = Table;

const IndeterminateCheckbox = (props) => {
    const {
        indeterminate,
        onChange,
        onCheckBoxChange,
        onIndeterminateCheckBoxChange,
        ...rest
    } = props;

    const ref = useRef(null);

    useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, indeterminate]);

    const handleChange = (e) => {
        onChange(e);
        onCheckBoxChange?.(e);
        onIndeterminateCheckBoxChange?.(e);
    };

    return (
        <Checkbox
            className="mb-0"
            ref={ref}
            onChange={(_, e) => handleChange(e)}
            {...rest}
        />
    );
};

const DataTable = forwardRef((props, ref) => {
    const {
        skeletonAvatarColumns,
        columns: columnsProp,
        data,
        loading,
        onCheckBoxChange,
        onIndeterminateCheckBoxChange,
        onPaginationChange,
        onSelectChange,
        onSort,
        pageSizes,
        selectable,
        skeletonAvatarProps,
        pagingData,
    } = props;
    const { pageSize, pageIndex, total } = pagingData;

    const [sorting, setSorting] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { t } = useTranslation();

    const pageSizeOption = useMemo(
        () =>
            pageSizes.map((number) => ({
                value: number,
                label: `${number} / page`,
            })),
        [pageSizes]
    );

    const handleCheckBoxChange = (checked, row) => {
        if (!loading) {
            onCheckBoxChange?.(checked, row);
        }
    };

    const handleIndeterminateCheckBoxChange = (checked, rows) => {
        if (!loading) {
            onIndeterminateCheckBoxChange?.(checked, rows);
        }
    };

    const handlePaginationChange = (page) => {
        if (!loading) {
            onPaginationChange?.(page);
        }
    };

    const handleSelectChange = (value) => {
        if (!loading) {
            onSelectChange?.(Number(value));
        }
    };

    useEffect(() => {
        if (Array.isArray(sorting) && sorting.length > 0) {
            const sortOrder = sorting[0].desc ? 'desc' : 'asc';
            const id = sorting[0].id;
            onSort?.({ order: sortOrder, key: id });
        } else {
            onSort?.({ order: '', key: '' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting]);

    const hasOldColumnMetaKey = columnsProp.some(
        (col) => col.Header || col.accessor || col.Cell
    );

    const finalColumns = useMemo(() => {
        const columns = columnsProp;

        if (selectable) {
            return [
                {
                    id: 'select',
                    header: ({ table }) => (
                        <IndeterminateCheckbox
                            checked={table.getIsAllRowsSelected()}
                            indeterminate={table.getIsSomeRowsSelected()}
                            onChange={table.getToggleAllRowsSelectedHandler()}
                            onIndeterminateCheckBoxChange={(e) => {
                                handleIndeterminateCheckBoxChange(
                                    e.target.checked,
                                    table.getRowModel().rows
                                );
                            }}
                        />
                    ),
                    cell: ({ row }) => (
                        <IndeterminateCheckbox
                            checked={row.getIsSelected()}
                            disabled={!row.getCanSelect()}
                            indeterminate={row.getIsSomeSelected()}
                            onChange={row.getToggleSelectedHandler()}
                            onCheckBoxChange={(e) =>
                                handleCheckBoxChange(
                                    e.target.checked,
                                    row.original
                                )
                            }
                        />
                    ),
                },
                ...columns,
            ];
        }
        return columns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnsProp, selectable]);

    const filteredData = useMemo(() => {
        if (!searchQuery) return data;

        const normalizeString = (str) =>
            str
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase();

        const normalizedQuery = normalizeString(searchQuery);

        return data.filter((row) => {
            return Object.values(row).some((value) =>
                normalizeString(String(value)).includes(normalizedQuery)
            );
        });
    }, [data, searchQuery]);

    const table = useReactTable({
        data: filteredData,
        columns: hasOldColumnMetaKey ? [] : finalColumns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        manualPagination: true,
        manualSorting: false,
        onSortingChange: setSorting,
        state: {
            sorting,
        },
    });

    const resetSorting = () => {
        table.resetSorting();
    };

    const resetSelected = () => {
        table.toggleAllRowsSelected(false);
    };

    useImperativeHandle(ref, () => ({
        resetSorting,
        resetSelected,
    }));

    if (hasOldColumnMetaKey) {
        const message =
            'You are using old react-table v7 column config, please use v8 column config instead, refer to our demo or https://tanstack.com/table/v8';

        if (process.env.NODE_ENV === 'development') {
            console.warn(message);
        }

        return <Alert>{message}</Alert>;
    }

    const inputIcon = (
        <span>
            <MdSearch size={20} />
        </span>
    );

    return (
        <Loading loading={loading && data.length !== 0} type="cover">
            <div className="flex items-center my-4 w-full justify-">
                <Input
                    type={'text'}
                    suffix={inputIcon}
                    placeholder={t('general.search')}
                    className="w-1/4"
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
            <Table>
                <THead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <Th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                    >
                                        {header.isPlaceholder ? null : (
                                            <div
                                                className={classNames(
                                                    header.column.getCanSort() &&
                                                        'cursor-pointer select-none',
                                                    loading &&
                                                        'pointer-events-none'
                                                )}
                                                onClick={header.column.getToggleSortingHandler()}
                                            >
                                                {flexRender(
                                                    header.column.columnDef
                                                        .header,
                                                    header.getContext()
                                                )}
                                                {header.column.getCanSort() && (
                                                    <Sorter
                                                        sort={header.column.getIsSorted()}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </Th>
                                );
                            })}
                        </Tr>
                    ))}
                </THead>
                {loading && data.length === 0 ? (
                    <TableRowSkeleton
                        columns={finalColumns.length}
                        rows={pagingData.pageSize}
                        avatarInColumns={skeletonAvatarColumns}
                        avatarProps={skeletonAvatarProps}
                    />
                ) : (
                    <TBody>
                        {table
                            .getRowModel()
                            .rows.slice(0, pageSize)
                            .map((row, index) => {
                                return (
                                    <Tr
                                        key={row.id}
                                        className={
                                            index % 2 === 0
                                                ? 'table-row-event'
                                                : 'table-row-odd'
                                        }
                                    >
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <Td key={cell.id}>
                                                    {flexRender(
                                                        cell.column.columnDef
                                                            .cell,
                                                        cell.getContext()
                                                    )}
                                                </Td>
                                            );
                                        })}
                                    </Tr>
                                );
                            })}
                    </TBody>
                )}
            </Table>
            <div className="flex items-center justify-between mt-4">
                <Pagination
                    pageSize={pageSize}
                    currentPage={pageIndex}
                    total={total}
                    onChange={handlePaginationChange}
                />
                <div style={{ minWidth: 130 }}>
                    <Select
                        size="sm"
                        menuPlacement="top"
                        isSearchable={false}
                        value={pageSizeOption.filter(
                            (option) => option.value === pageSize
                        )}
                        options={pageSizeOption}
                        onChange={(option) => handleSelectChange(option.value)}
                    />
                </div>
            </div>
        </Loading>
    );
});

DataTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    loading: PropTypes.bool,
    onCheckBoxChange: PropTypes.func,
    onIndeterminateCheckBoxChange: PropTypes.func,
    onPaginationChange: PropTypes.func,
    onSelectChange: PropTypes.func,
    onSort: PropTypes.func,
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    selectable: PropTypes.bool,
    skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
    skeletonAvatarProps: PropTypes.object,
    pagingData: PropTypes.shape({
        total: PropTypes.number,
        pageIndex: PropTypes.number,
        pageSize: PropTypes.number,
    }),
};

DataTable.defaultProps = {
    pageSizes: [10, 25, 50, 100],
    pagingData: {
        total: 0,
        pageIndex: 1,
        pageSize: 10,
    },
    data: [],
    columns: [],
    selectable: false,
    loading: false,
};

export default DataTable;
