import React, { useMemo, forwardRef } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Day from './Day';
import getDayProps from './props/getDayProps';
import { isSameDate, getWeekdaysNames, getMonthDays } from '../../utils';
import { useConfig } from '../../../ConfigProvider';

const noop = () => false;

/**
 * Month component is a custom component to render the month in the date picker.
 * It uses the `forwardRef` function to pass the ref to the month.
 * It uses the `useMemo` hook to memoize the month days.
 * It uses the `classNames` utility to conditionally apply classes.
 * It uses the `dayjs` library to work with dates.
 * It uses the `Day` component to render the day in the date picker.
 * It uses the `getDayProps` utility to get the day props.
 * It uses the `isSameDate`, `getWeekdaysNames`, and `getMonthDays` utilities.
 * It uses the `useConfig` hook to get the locale.
 * It uses the `getDayProps` utility to get the day props.
 *
 * @param {object} props
 * @param {string} [props.className] - The class name of the
 * month.
 * @param {Date} props.month - The month to render.
 * @param {Date|Date[]} props.value - The selected date or dates.
 * @param {function} props.onChange - The function to call when
 * the date changes.
 * @param {boolean} [props.disableOutOfMonth] - The flag to
 * disable the out of month dates.
 * @param {string} [props.locale] - The locale to use.
 * @param {function} [props.dayClassName] - The function to
 * get the class name of the day.
 * @param {function} [props.dayStyle] - The function to get
 * the style of the day.
 * @param {object} [props.styles] - The styles to apply.
 * @param {Date} [props.minDate] - The minimum date.
 * @param {Date} [props.maxDate] - The maximum date.
 * @param {function} [props.disableDate] - The function to
 * disable the date.
 * @param {function} [props.onDayMouseEnter] - The function to
 * call when the mouse enters the day.
 * @param {boolean} [props.range] - The flag to enable the
 * range selection.
 * @param {boolean} [props.hideWeekdays] - The flag to hide
 * the weekdays.
 * @param {boolean} [props.fullWidth] - The flag to make the
 * month full width.
 * @param {boolean} [props.preventFocus] - The flag to prevent
 * the focus.
 * @param {boolean} [props.focusable] - The flag to make the
 * month focusable.
 * @param {string} [props.firstDayOfWeek] - The first day of
 * the week.
 * @param {function} [props.onDayKeyDown] - The function to
 * call when the key is down on the day.
 * @param {array} [props.daysRefs] - The refs of the days.
 * @param {boolean} [props.hideOutOfMonthDates] - The flag to
 * hide the out of month dates.
 * @param {function} [props.isDateInRange] - The function to
 * check if the date is in range.
 * @param {function} [props.isDateFirstInRange] - The function
 * to check if the date is the first in range.
 * @param {function} [props.isDateLastInRange] - The function
 * to check if the date is the last in range.
 * @param {function} [props.renderDay] - The function to render
 * the day.
 * @param {string} [props.weekdayLabelFormat] - The weekday
 * label format.
 *
 * @returns {React.Element} The month component.
 */
const Month = forwardRef((props, ref) => {
    const {
        className,
        month,
        value,
        onChange,
        disableOutOfMonth,
        locale,
        dayClassName,
        dayStyle,
        styles,
        minDate,
        maxDate,
        disableDate,
        onDayMouseEnter,
        range,
        hideWeekdays,
        fullWidth,
        preventFocus,
        focusable,
        firstDayOfWeek,
        onDayKeyDown,
        daysRefs,
        hideOutOfMonthDates,
        isDateInRange = noop,
        isDateFirstInRange = noop,
        isDateLastInRange = noop,
        renderDay,
        weekdayLabelFormat,
        weekendDays,
        ...rest
    } = props;

    const { locale: themeLocale } = useConfig();

    const finalLocale = locale || themeLocale;
    const days = getMonthDays(month, firstDayOfWeek);

    const weekdays = getWeekdaysNames(
        finalLocale,
        firstDayOfWeek,
        weekdayLabelFormat
    ).map((weekday) => (
        <th className="week-day-cell" key={weekday}>
            <span className="week-day-cell-content">{weekday}</span>
        </th>
    ));

    const hasValue = Array.isArray(value)
        ? value.every((item) => item instanceof Date)
        : value instanceof Date;

    const hasValueInMonthRange =
        value instanceof Date &&
        dayjs(value).isAfter(dayjs(month).startOf('month')) &&
        dayjs(value).isBefore(dayjs(month).endOf('month'));

    const firstIncludedDay = useMemo(
        () =>
            days
                .flatMap((_) => _)
                .find((date) => {
                    const dayProps = getDayProps({
                        date,
                        month,
                        hasValue,
                        minDate,
                        maxDate,
                        value,
                        disableDate,
                        disableOutOfMonth,
                        range,
                        weekendDays,
                    });

                    return !dayProps.disabled && !dayProps.outOfMonth;
                }) || dayjs(month).startOf('month').toDate(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const rows = days.map((row, rowIndex) => {
        const cells = row.map((date, cellIndex) => {
            const dayProps = getDayProps({
                date,
                month,
                hasValue,
                minDate,
                maxDate,
                value,
                disableDate,
                disableOutOfMonth,
                range,
                weekendDays,
            });

            const onKeyDownPayload = { rowIndex, cellIndex, date };

            return (
                <td className={classNames('date-picker-cell')} key={cellIndex}>
                    <Day
                        ref={(button) => {
                            if (daysRefs) {
                                if (!Array.isArray(daysRefs[rowIndex])) {
                                    daysRefs[rowIndex] = [];
                                }
                                daysRefs[rowIndex][cellIndex] = button;
                            }
                        }}
                        onClick={() =>
                            typeof onChange === 'function' && onChange(date)
                        }
                        onMouseDown={(event) =>
                            preventFocus && event.preventDefault()
                        }
                        outOfMonth={dayProps.outOfMonth}
                        weekend={dayProps.weekend}
                        inRange={
                            dayProps.inRange || isDateInRange(date, dayProps)
                        }
                        firstInRange={
                            dayProps.firstInRange ||
                            isDateFirstInRange(date, dayProps)
                        }
                        lastInRange={
                            dayProps.lastInRange ||
                            isDateLastInRange(date, dayProps)
                        }
                        firstInMonth={isSameDate(date, firstIncludedDay)}
                        selected={dayProps.selected || dayProps.selectedInRange}
                        hasValue={hasValueInMonthRange}
                        onKeyDown={(event) =>
                            typeof onDayKeyDown === 'function' &&
                            onDayKeyDown(onKeyDownPayload, event)
                        }
                        className={
                            typeof dayClassName === 'function'
                                ? dayClassName(date, dayProps)
                                : null
                        }
                        style={
                            typeof dayStyle === 'function'
                                ? dayStyle(date, dayProps)
                                : null
                        }
                        disabled={dayProps.disabled}
                        onMouseEnter={
                            typeof onDayMouseEnter === 'function'
                                ? onDayMouseEnter
                                : noop
                        }
                        fullWidth={fullWidth}
                        focusable={focusable}
                        hideOutOfMonthDates={hideOutOfMonthDates}
                        styles={styles}
                        renderDay={renderDay}
                        isToday={isSameDate(date, new Date())}
                        value={date}
                    />
                </td>
            );
        });

        return (
            <tr className={classNames('date-picker-week-cell')} key={rowIndex}>
                {cells}
            </tr>
        );
    });

    return (
        <table
            className={classNames('picker-table', className)}
            ref={ref}
            cellSpacing="0"
            {...rest}
        >
            {!hideWeekdays && (
                <thead>
                    <tr>{weekdays}</tr>
                </thead>
            )}
            <tbody>{rows}</tbody>
        </table>
    );
});

Month.defaultProps = {
    disableOutOfMonth: false,
    hideWeekdays: false,
    size: 'sm',
    fullWidth: false,
    preventFocus: false,
    focusable: true,
    firstDayOfWeek: 'monday',
    hideOutOfMonthDates: false,
    weekendDays: [0, 6],
};

export default Month;
