/**
 * Get the end of the week for a given date.
 * @param {Date} date - The date to get the end of the week for
 * @param {string} [firstDayOfWeek='monday'] - The first day of the week
 * @returns {Date}
 */
export function getEndOfWeek(date, firstDayOfWeek = 'monday') {
    const value = new Date(date);
    const day = value.getDay();
    const isSunday = firstDayOfWeek === 'sunday';

    const clampToLastDay = 7 - (isSunday ? day + 1 : day);

    if ((isSunday && day !== 6) || day !== 0) {
        value.setDate(value.getDate() + clampToLastDay);
    }

    return value;
}
