import dayjs from 'dayjs';

/**
 * Check if a month is in the range of minDate and maxDate.
 * @param {Object} range - The range to check against
 * @param {Date} range.date - The date to check
 * @param {Date} range.minDate - The minimum date
 * @param {Date} range.maxDate - The maximum date
 * @returns {boolean}
 */
export function isMonthInRange({ date, minDate, maxDate }) {
    const hasMinDate = minDate instanceof Date;
    const hasMaxDate = maxDate instanceof Date;

    if (!hasMaxDate && !hasMinDate) {
        return true;
    }

    const endOfMonth = dayjs(date).endOf('month');
    const startOfMonth = dayjs(date).startOf('month');
    const maxInRange = hasMaxDate ? startOfMonth.isBefore(maxDate) : true;
    const minInRange = hasMinDate ? endOfMonth.isAfter(minDate) : true;
    return maxInRange && minInRange;
}
