import React from 'react';
import { HiOutlineMenuAlt2, HiOutlineMenu } from 'react-icons/hi';

/**
 * The `NavToggle` component displays a toggle icon for the navigation bar.
 * It uses the `HiOutlineMenu` and `HiOutlineMenuAlt2` icons to provide a toggle button.
 * @param {object} props - The props of the component.
 * @param {boolean} props.toggled - The toggle state of the navigation bar.
 * @param {string} props.className - The class name of the component.
 * @returns {JSX.Element} - The rendered `NavToggle` component.
 */
const NavToggle = ({ toggled, className }) => {
    return (
        <div className={className}>
            {toggled ? <HiOutlineMenu /> : <HiOutlineMenuAlt2 />}
        </div>
    );
};

export default NavToggle;
