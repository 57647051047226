/**
 * Get the range of years for a decade
 * @param {number} year - The year to get the decade range for
 * @returns {number[]}
 */
export function getDecadeRange(year) {
    const rounded = year - (year % 10) - 1;
    const range = [];
    for (let i = 0; i < 12; i += 1) {
        const rangeYear = rounded + i;
        range.push(rangeYear);
    }

    return range;
}
