/**
 * Create a unique id
 * @param {number} len - Length of the unique id
 * @returns {string} The unique id
 */
const createUID = (len) => {
    const buf = [];
    const chars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charlen = chars.length;
    const length = len || 10;

    for (let i = 0; i < length; i++) {
        buf[i] = chars.charAt(Math.floor(Math.random() * charlen));
    }
    return buf.join('');
};

export default createUID;
