import React from 'react';
import PropTypes from 'prop-types';

/**
 * TextEllipsis component displays a text with ellipsis if the text is too long.
 * It truncates the text to the specified length and appends ellipsis.
 */
const TextEllipsis = (props) => {
    const { text, maxTextCount } = props;

    return (
        <>
            {(text && text.length) > maxTextCount
                ? text.substring(0, maxTextCount - 3) + '...'
                : text}
        </>
    );
};

TextEllipsis.propTypes = {
    text: PropTypes.string,
};

TextEllipsis.defaultProps = {
    text: '',
    maxTextCount: 0,
};

export default TextEllipsis;
