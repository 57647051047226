import { useRef } from 'react';

/**
 * useUncertainRef is a custom hook to handle uncertain ref.
 *
 * @param {Object} ref - The ref object.
 *
 * @returns {Object}
 */
export default function useUncertainRef(ref) {
    const newRef = useRef();

    if (ref) {
        return ref;
    }

    return newRef;
}
