/**
 * App Config File
 */
const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: '/',
    unAuthenticatedEntryPath: '/',
    tourPath: '/',
    locale: 'fr',
    enableMock: false,
};

export default appConfig;
