/**
 * Check if two dates are in the same month
 * @param {Date} date - The first date to check
 * @param {Date} comparison - The second date to check
 * @returns {boolean}
 */
export function isSameMonth(date, comparison) {
    return (
        date.getFullYear() === comparison.getFullYear() &&
        date.getMonth() === comparison.getMonth()
    );
}
