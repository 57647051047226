import React from 'react';
import CalendarBase from './CalendarBase';
import { isSameDate } from './utils';

/**
 * Calendar component for selecting a single or multiple dates.
 * @param {Object} props - The component props
 * @param {boolean} [props.multipleSelection] - The flag to enable multiple date selection
 * @param {Date[]} props.value - The selected date
 * @param {function} props.onChange - The function to call when the date changes
 * @returns {React.ReactElement}
 */
const Calendar = (props) => {
    const { multipleSelection, value, onChange, ...rest } = props;

    const handleChange = (date) => {
        if (!multipleSelection) {
            return onChange(date);
        }

        const isSelected = value.some((val) => isSameDate(val, date));

        return onChange(
            isSelected
                ? value.filter((val) => !isSameDate(val, date))
                : [...value, date]
        );
    };

    return <CalendarBase onChange={handleChange} value={value} {...rest} />;
};

export default Calendar;
