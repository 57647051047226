import { createSlice } from '@reduxjs/toolkit';

/**
 * Slice for the common.
 * This slice handles the common.
 * It stores the current route key and data.
 * The data is used to store the data fetched from the API.
 * The current route key is used to store the current route key.
 * The current route key is used to highlight the current route in the sidebar.
 * The data is used to store the data fetched from the API.
 */
export const initialState = {
    currentRouteKey: '',
    data: [],
};

export const commonSlice = createSlice({
    name: 'base/common',
    initialState,
    reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload;
        },
        setData: (state, action) => {
            state.data = action.payload;
        },
        editData: (state, action) => {
            const updatedData = action.payload;
            state.data = state.data.map((item) =>
                item.uuid === updatedData.uuid ? updatedData : item
            );
        },
        deleteData: (state, action) => {
            state.data = state.data.filter(
                (item) => item.uuid !== action.payload
            );
        },
    },
});

export const { setCurrentRouteKey, setData, editData, deleteData } =
    commonSlice.actions;

export default commonSlice.reducer;
