import React, { useState } from 'react';
import classNames from 'classnames';
import Header from './Header';
import { getDecadeRange, formatYear } from '../utils';
import { useConfig } from '../../ConfigProvider';

/**
 * YearTable component is a custom component to render the year table in the date picker.
 * It uses the `useState` hook to manage the decade state.
 * It uses the `Header` component to render the header in the date picker.
 * It uses the `classNames` utility to conditionally apply classes.
 * It uses the `getDecadeRange` utility to get the decade range.
 * It uses the `formatYear` utility to format the year.
 * It uses the `useConfig` hook to get the theme color.
 *
 * @param {object} props
 * @param {string} [props.className] - The class name of the
 * year table.
 * @param {number} props.value - The value of the year table.
 * @param {function} props.onChange - The function to call when
 * the year changes.
 * @param {number} props.minYear - The minimum year.
 * @param {number} props.maxYear - The maximum year.
 * @param {boolean} [props.preventFocus] - The flag to prevent
 * the focus.
 * @param {string} [props.yearLabelFormat] - The format of the
 * year label.
 * @returns {React.ReactElement}
 */
const YearTable = (props) => {
    const {
        className,
        value,
        onChange,
        minYear,
        maxYear,
        preventFocus,
        yearLabelFormat = 'YYYY',
        ...rest
    } = props;

    const { themeColor, primaryColorLevel } = useConfig();

    const [decade, setDecade] = useState(value);
    const range = getDecadeRange(decade);

    const years = range.map((year) => {
        const disabled = year < minYear || year > maxYear;

        const active = year === value;

        return (
            <button
                key={year}
                onClick={() => onChange(year)}
                disabled={disabled}
                onMouseDown={(event) => preventFocus && event.preventDefault()}
                className={classNames(
                    'year-picker-cell',
                    active &&
                        !disabled &&
                        `bg-${themeColor}-${primaryColorLevel} text-white year-picker-cell-active`,
                    !active && !disabled && 'hover:bg-gray-100',
                    disabled && 'year-picker-cell-disabled'
                )}
            >
                {formatYear(year, yearLabelFormat)}
            </button>
        );
    });

    return (
        <div className={classNames('year-picker', className)} {...rest}>
            <Header
                label={`${formatYear(range[0], yearLabelFormat)} - ${formatYear(
                    range[range.length - 1],
                    yearLabelFormat
                )}`}
                hasPrevious={
                    typeof minYear === 'number' ? minYear < range[0] : true
                }
                hasNext={
                    typeof maxYear === 'number'
                        ? maxYear > range[range.length - 1]
                        : true
                }
                onNext={() => setDecade((current) => current + 10)}
                onPrevious={() => setDecade((current) => current - 10)}
                nextLevelDisabled
                nextLabel={'Next decade'}
                previousLabel={'Previous decade'}
                preventFocus={preventFocus}
            />
            <div className="year-table">{years}</div>
        </div>
    );
};

export default YearTable;
