import { combineReducers } from '@reduxjs/toolkit';
import common from './commonSlice';

/**
 * Reducer for the common.
 */
const reducer = combineReducers({
    common,
});

export default reducer;
