import { createSlice } from '@reduxjs/toolkit';

/**
 * Slice for the organisation.
 * This slice handles the organisation.
 * It stores the current organisation and organisation list.
 * The current organisation is used to store the current organisation.
 * The organisation list is used to store the list of organisations.
 * The organisation list is used to display the list of organisations in the dropdown.
 * The current organisation is used to store the current organisation.
 */
const initialState = {
    currentOrg: [],
    orgList: [],
};

export const organisationSlice = createSlice({
    name: 'organisation',
    initialState,
    reducers: {
        setOrg: (state, action) => {
            state.currentOrg = action.payload;
            localStorage.setItem('currentOrg', JSON.stringify(action.payload));
        },
        setOrgList: (state, action) => {
            state.orgList = action.payload;
            localStorage.setItem('orgList', JSON.stringify(action.payload));
        },
        editOrgList: (state, action) => {
            const updatedOrg = action.payload;
            state.orgList = state.orgList.map((item) =>
                item.value === updatedOrg.value ? updatedOrg : item
            );
        },
        deleteOrgList: (state, action) => {
            state.orgList = state.orgList.filter(
                (org) => org.value !== action.payload
            );
        },
    },
});

export const { setOrg, setOrgList, editOrgList, deleteOrgList } =
    organisationSlice.actions;

export default organisationSlice.reducer;
