/**
 * Creates a handler for the AM/PM input field.
 * @param {Object} options
 * @param {string} options.amLabel - The AM label.
 * @param {string} options.pmLabel - The PM label.
 * @param {Function} options.onChange - The change handler.
 * @param {Object} options.nextRef - The reference to the next input field.
 * @returns {Function}
 */
export function createAmPmHandler({ amLabel, pmLabel, onChange, nextRef }) {
    return (value, triggerShift) => {
        const testRegex = new RegExp(`(^(${amLabel}|${pmLabel})?$)`);
        const valLower = value.toLowerCase();

        if (valLower === amLabel || valLower === pmLabel) {
            onChange(valLower);
            triggerShift && nextRef?.current?.focus();
            triggerShift && nextRef?.current?.select();
            return;
        }

        if (!testRegex.test(valLower)) {
            return;
        }

        onChange(valLower);
    };
}
