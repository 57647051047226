/**
 * Check if one is in ofTarget
 * @param {any} one
 * @param {any[]} ofTarget
 * @returns {boolean}
 */
export default function arrayIndexOf(one, ofTarget) {
    if (Array.isArray(ofTarget)) {
        return ofTarget.indexOf(one) >= 0;
    }
    return one === ofTarget;
}
