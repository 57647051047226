import React, { forwardRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

/**
 * ScrollBar component is a component to render scrollbar element.
 *
 * @param {Object} props - The props of the component.
 * @returns {React.Element}
 */
const ScrollBar = forwardRef((props, ref) => {
    const { direction = 'ltr', ...rest } = props;

    return (
        <Scrollbars
            ref={ref}
            renderView={(props) => (
                <div
                    {...props}
                    style={{
                        ...props.style,
                        ...(direction === 'rtl' && {
                            marginLeft: props.style.marginRight,
                            marginRight: 0,
                        }),
                    }}
                />
            )}
            {...rest}
        />
    );
});

export default ScrollBar;
