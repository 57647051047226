import { isSameMonth } from '../../../utils';

/**
 * Returns whether a date is outside the current month.
 * @param {Date} date The date to check.
 * @param {Date} month The month to compare against.
 * @returns {boolean} Whether the date is outside the month.
 */
export default function isOutside(date, month) {
    return !isSameMonth(date, month);
}
