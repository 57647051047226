import React from 'react';
import PropTypes from 'prop-types';
import { FormContextProvider, FormContextConsumer } from '../context';
import { useConfig } from '../../ConfigProvider';
import { SIZES, LAYOUT } from '../../utils/constant';
import classNames from 'classnames';

/**
 * FormContainer component is a custom component to render a form container.
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - The children of the form container.
 * @param {string} [props.className] - The class name of the form container.
 * @param {string} [props.layout] - The layout of the form container.
 * @param {string} [props.size] - The size of the form container.
 * @param {string|number} [props.labelWidth] - The width of the label.
 *
 * @returns {React.ReactElement}
 */
const FormContainer = (props) => {
    const { controlSize } = useConfig();

    const { children, labelWidth, layout, size, className } = props;

    const contextValue = {
        labelWidth,
        layout,
        size: size || controlSize,
    };

    return (
        <FormContextProvider value={contextValue}>
            <FormContextConsumer>
                {(context) => {
                    return (
                        <div
                            className={classNames(
                                'form-container',
                                context.layout,
                                className
                            )}
                        >
                            {children}
                        </div>
                    );
                }}
            </FormContextConsumer>
        </FormContextProvider>
    );
};

FormContainer.propTypes = {
    layout: PropTypes.oneOf([
        LAYOUT.HORIZONTAL,
        LAYOUT.VERTICAL,
        LAYOUT.INLINE,
    ]),
    size: PropTypes.oneOf([SIZES.LG, SIZES.SM, SIZES.MD]),
    labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FormContainer.defaultProps = {
    layout: LAYOUT.VERTICAL,
    size: SIZES.MD,
    labelWidth: 100,
};

export default FormContainer;
