import { useRef } from 'react';
import uniqueId from 'lodash/uniqueId';
import createUID from '../utils/createUid';

/**
 * useUniqueId is a custom hook to generate unique IDs.
 *
 * @param {string} prefix - The prefix.
 * @param {number} len - The length.
 *
 * @returns {string}
 */
export default function useUniqueId(prefix, len) {
    const idRef = useRef();

    if (!idRef.current) {
        idRef.current = `${uniqueId(prefix)}-${createUID(len)}`;
    }

    return idRef.current;
}
