import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Container component is a custom component to wrap the content in a container.
 * It uses the `forwardRef` function to pass the ref to the container.
 */
const Container = forwardRef((props, ref) => {
    const { className, children, asElement: Component, ...rest } = props;

    return (
        <Component
            ref={ref}
            className={classNames('container mx-auto', className)}
            {...rest}
        >
            {children}
        </Component>
    );
});

Container.defaultProps = {
    asElement: 'div',
};

Container.propTypes = {
    asElement: PropTypes.string,
};

export default Container;
