import dayjs from 'dayjs';
import { getStartOfWeek } from './getStartOfWeek';

/**
 * Get the names of the weekdays for a given locale.
 * @param {string} locale - The locale to use.
 * @param {string} [firstDayOfWeek='monday'] - The first day of the week
 * @param {string} [format='dd'] - The format to use.
 * @returns {string[]}
 */
export function getWeekdaysNames(
    locale,
    firstDayOfWeek = 'monday',
    format = 'dd'
) {
    const names = [];
    const date = getStartOfWeek(new Date(), firstDayOfWeek);

    for (let i = 0; i < 7; i += 1) {
        names.push(dayjs(date).locale(locale).format(format));
        date.setDate(date.getDate() + 1);
    }

    return names;
}
